import s from "./Footer.module.css";
import {
  UserOutlined,
  DollarOutlined,
  SkinOutlined,
  MoreOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";
import shop from "../../assets/Buttons/shop.png";
import { NavLink } from "react-router-dom";

const Footer = ({
  setActiveWard,
  setactiveUpdates,
  activeWard,
  activeUpdates,
  setActiveProfile,
  activeProfile,
}) => {
  return (
    <>
      <div className={s.nav}>
        <NavLink to="/">
          <div
            className={
              activeWard || activeProfile || activeUpdates ? s.icon : s.iconA
            }
            onClick={() => {
              setactiveUpdates(false);
              setActiveWard(false);
              setActiveProfile(false);
            }}
          >
            <HomeOutlined />
            <div className={s.name}>Scene</div>
          </div>
        </NavLink>
        <NavLink to="/upgrade">
          <div
            className={!activeUpdates ? s.icon : s.iconA}
            onClick={() => {
              setActiveWard(false);
              setActiveProfile(false);
              setactiveUpdates(!activeUpdates);
            }}
          >
            <MoreOutlined />
            <div className={s.name}>Upgrade</div>
          </div>
        </NavLink>
        <div
          className={!activeUpdates ? s.icon : s.iconA}
          onClick={() => {
            setActiveWard(false);
            setActiveProfile(false);
            setactiveUpdates(!activeUpdates);
          }}
        >
          <DollarOutlined />
          <div className={s.name}>Activities</div>
        </div>
        <div
          className={!activeWard ? s.icon : s.iconA}
          onClick={() => {
            setactiveUpdates(false);
            setActiveProfile(false);
            setActiveWard(!activeWard);
          }}
        >
          <SkinOutlined />
          <div className={s.name}>Backstage</div>
        </div>
        <div
          className={!activeProfile ? s.icon : s.iconA}
          onClick={() => {
            setactiveUpdates(false);
            setActiveWard(false);
            setActiveProfile(!activeProfile);
          }}
        >
          <UserOutlined />
          <div className={s.name}>Profile</div>
        </div>
      </div>
    </>
  );
};

export default Footer;
