import s from "./Updates.module.css"; // Предположим, что у вас есть стили для выделения
import React, { useEffect, useState } from "react";
import { Avatar, List, Skeleton } from "antd";
import axios from "axios";
import { BarsOutlined } from "@ant-design/icons";

const Updates = () => {
  const [updates, setUpdates] = useState([]);
  const [userUpdates, setUserUpdates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState("");
  // Получаем все улучшения
  useEffect(() => {
    axios
      .post(`https://blogerverse.com/api/updates `, { category: category })
      .then((response) => {
        setUpdates(response.data); // Установка данных о всех улучшениях
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching updates:", error);
        setLoading(false);
      });
  }, [category]);

  // Получаем улучшения, которые уже купил пользователь
  useEffect(() => {
    axios
      .get(`https://blogerverse.com/api/user-updates/${1}`)
      .then((response) => {
        setUserUpdates(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user updates:", error);
      });
  }, []);

  // Функция, которая проверяет, было ли улучшение куплено пользователем
  const isUpdatePurchased = (updateId) => {
    return userUpdates.includes(updateId);
  };

  return (
    <div
      style={{
        backgroundColor: "#24242e",
        height: "600px",
        width: "100vw",
        bottom: "69px",
        position: "absolute",
        zIndex: 10,
      }}
    >
      <div className={s.category}>
        <div
          className={!category ? s.categoryBtnActive : s.categoryBtn}
          onClick={() => setCategory("")}
        >
          All
        </div>
        <div
          className={category === "like" ? s.categoryBtnActive : s.categoryBtn}
          onClick={() => setCategory("like")}
        >
          Likes
        </div>
        <div
          className={category === "coin" ? s.categoryBtnActive : s.categoryBtn}
          onClick={() => setCategory("coin")}
        >
          Coins
        </div>
      </div>
      <List
        className="demo-loadmore-list"
        loading={loading} // Загрузка
        itemLayout="horizontal"
        dataSource={updates} // Источник данных
        renderItem={(item) => (
          <List.Item
            actions={[<a key="list-loadmore-edit">Купить</a>]}
            style={{
              backgroundColor: isUpdatePurchased(item.id) ? "#24242e" : "white", // Цвет для выполненных обновлений
            }}
          >
            <Skeleton avatar title={false} loading={loading} active>
              <List.Item.Meta
              
                avatar={<Avatar icon={<BarsOutlined />} />}
                title={<a href="#">{item.name}</a>} // Ссылка на тип улучшения
                description={`${
                  item.cost_coins ? `${item.cost_coins} монет` : ""
                }
               ${
                  item.cost_likes ? `${item.cost_likes} лайков ` : ""
                }
                ${
                  item.cost_money ? `${item.cost_money} долларов ` : ""
                }`} // Описание улучшения
              />
            </Skeleton>
          </List.Item>
        )}
      />
    </div>
  );
};

export default Updates;
