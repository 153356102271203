import s from "./Main.module.css";
import React, { useEffect, useState } from "react";
import { Avatar, Button, List, Skeleton } from "antd";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  setWardrobeItem,
  setWardrobeUserData,
  setWardrobeItems,
} from "../../Redux/user/userReducer";
import shop from "../../assets/Buttons/shop.png"
import Header from "../Header";
export const MainPage = ({ activeProfile, activeWard, activeUpdates }) => {
  const user = useSelector((state) => state.user);
  useEffect(() => {

  }, [user])
  return (
    <div className={activeWard ? s.main : s.mainWiderobe}>
      <div>
        <Header />
        {/* {!activeProfile && !activeUpdates && !activeWard && <MainBtn />} */}
        <Person activeWard={activeWard} />
      </div>
    </div>
  );
};

const Person = ({ activeWard }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState(10000);
  const [timeLeft2, setTimeLeft2] = useState(1000);
  const [smallTimer, setSmallTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(true);

  // Обратный отсчёт основного таймера
  useEffect(() => {
    if (timeLeft <= 0) {
      setIsRunning(false);
      // onComplete();
      return;
    }
    const timerId = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  // Индикатор обновления (маленький таймер)
  useEffect(() => {
    if (!isRunning) return;

    const smallTimerId = setInterval(() => {
      setSmallTimer((prev) => (prev + 1) % 10); // цикл обновления до 10
    }, 1000);

    return () => clearInterval(smallTimerId);
  }, [isRunning]);
  
  // Форматирование времени в минуты:секунды
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };
  useEffect(() => {
    
    user.id ?
    axios.get(`https://blogerverse.com/api/usercustomlist?id=${user.id}`).then((res) => {
      let customId = res.data.customisation;
      dispatch(setWardrobeUserData(customId));
      dispatch(setWardrobeItems(res.data.items));
    }) :
    axios.get(`https://blogerverse.com/api/usercustomlist?id=5`).then((res) => {
      let customId = res.data.customisation;
      dispatch(setWardrobeUserData(customId));
      dispatch(setWardrobeItems(res.data.items));
    });
  }, [user.id]);

  return (
    <div style={{ paddingTop: "10px" }}>
      <div className={s.leftIcons}>
        <>
        <AppstoreOutlined />
        <div className={s.timer}>
        {isRunning ? formatTime(timeLeft) : ""}
      </div>
        </>
        <>
        <AppstoreOutlined />
        <div className={s.timer}>
        {/* {isRunning ? formatTime(timeLeft2) : ""} */}
      </div>
        </>
      </div>
      <div className={s.person}>
        <img
          style={{
            zIndex: "1",
            opacity: `${activeWard ? 0.7 : 1}`,
          }}
          src={`https://blogerverse.com${user.dummyUrl}`}
        />
        <img
          style={{
            zIndex: "2",
            opacity: `${activeWard ? 0.8 : 1}`,
          }}
          src={`https://blogerverse.com${user.eyesUrl}`}
        />
        <img
          style={{
            zIndex: "3",
            opacity: `${activeWard ? 0.8 : 1}`,
          }}
          src={`https://blogerverse.com${user.hairUrl}`}
        />
        <img
          style={{
            zIndex: "3",
            opacity: `${activeWard ? 0.8 : 1}`,
          }}
          src={`https://blogerverse.com${user.bodyUrl}`}
        />
        <img
          style={{
            zIndex: "2",
            opacity: `${activeWard ? 0.8 : 1}`,
          }}
          src={`https://blogerverse.com${user.mouthUrl}`}
        />
        <img
          style={{
            zIndex: "2",
            opacity: `${activeWard ? 0.85 : 1}`,
          }}
          src={`https://blogerverse.com${user.legsUrl}`}
        />
        <img
          style={{
            zIndex: "1",
            opacity: `${activeWard ? 0.7 : 1}`,
          }}
          src={`https://blogerverse.com${user.shoesUrl}`}
        />
      </div>
      <div className={s.rightIcons}>
        <img src={shop}/>
        <AppstoreOutlined />
      </div>
    </div>
  );
};

const MainBtn = () => {
  function registrateUser() {
    axios.post("https://blogerverse.com/api/create-user/");
  }
  return (
    <div style={{ margin: "0px auto", display: "flex" }}>
      <button className={s.mainBtn} size="medium" style={{ margin: "0 auto" }}>
        Создать видео
      </button>
    </div>
  );
};

export default MainPage;
