import { SET_STATS_USER_DATA } from "../types";

const initialState = {
  coin_count: 0,
  like_count: 0,
  income_like_second: 1,
  income_coin_second: 0.1,
};

export const userStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATS_USER_DATA:
      
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const setStatsUserData = ({
  coin_count,
  like_count,
  income_like_second,
  income_coin_second,
}) => ({
  type: SET_STATS_USER_DATA,
  payload: { coin_count, like_count, income_like_second, income_coin_second },
});
