import {SET_EN, SET_RU} from "../types";

const initialState = {
    lang: 'ru'
};

export const langReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RU:
            return { lang: 'ru' };
        case SET_EN:
            return { lang: 'en' };
        default:
            return state;
    }
};