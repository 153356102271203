import s from "./Quests.module.css"; // Предположим, что у вас есть стили для выделения
import React, { useEffect, useState } from "react";
import { Avatar, List, Skeleton } from "antd";
import axios from "axios";
import { BarsOutlined } from "@ant-design/icons";
import DailyBonus from "./DailyQ.jsx"

const Quests = () => {
  const [updates, setUpdates] = useState([]);
  const [userUpdates, setUserUpdates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState("");
  // Получаем все улучшения
  useEffect(() => {
    axios
      .post(`https://blogerverse.com/api/updates `, { category: category })
      .then((response) => {
        setUpdates(response.data); // Установка данных о всех улучшениях
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching updates:", error);
        setLoading(false);
      });
  }, [category]);

  // Получаем улучшения, которые уже купил пользователь
  useEffect(() => {
    axios
      .get(`https://blogerverse.com/api/user-updates/${1}`)
      .then((response) => {
        setUserUpdates(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user updates:", error);
      });
  }, []);

  // Функция, которая проверяет, было ли улучшение куплено пользователем
  const isUpdatePurchased = (updateId) => {
    return userUpdates.includes(updateId);
  };

  return (
    <div
      style={{
        backgroundColor: "#24242e",
        height: "600px",
        width: "100vw",
        bottom: "69px",
        position: "absolute",
        zIndex: 10,
      }}
    >
      <div className={s.category}>
        <div
          className={!category ? s.categoryBtnActive : s.categoryBtn}
          onClick={() => setCategory("")}
        >
          All
        </div>
        <div
          className={category === "like" ? s.categoryBtnActive : s.categoryBtn}
          onClick={() => setCategory("like")}
        >
          Likes
        </div>
        <div
          className={category === "coin" ? s.categoryBtnActive : s.categoryBtn}
          onClick={() => setCategory("coin")}
        >
          Coins
        </div>
      </div>
      <DailyBonus />
    </div>
  );
};

export default Quests;
