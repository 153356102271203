import s from "./Profile.module.css"; // Предположим, что у вас есть стили для выделения
import React, { useEffect, useState } from "react";
import { Avatar, List, Skeleton } from "antd";
import axios from "axios";
import { BarsOutlined } from "@ant-design/icons";

const Profile = () => {
  const [updates, setUpdates] = useState([]);
  const [userUpdates, setUserUpdates] = useState([]);
  const [loading, setLoading] = useState(true);

  // Получаем все улучшения
  useEffect(() => {
    axios
      .get("https://blogerverse.com/api/clicks?id=1")
      .then((response) => {
        setUpdates(response.data); // Установка данных о всех улучшениях
        setLoading(false);
        
      })
      .catch((error) => {
        console.error("Error fetching updates:", error);
        setLoading(false);
      });
  }, []);

  // Получаем улучшения, которые уже купил пользователь
  useEffect(() => {
    axios
      .get(`https://blogerverse.com/api/char?id=1`)
      .then((response) => {
        setUserUpdates(response.data);
        
      })
      .catch((error) => {
        console.error("Error fetching user updates:", error);
      });
  }, []);

  

  return (
   <div className={s.profile}>
    <div className={s.profileGradient}>

    </div>
      rvgrtgrtgr
      <div>
        ervgergtrgw
      </div>

   </div>
  );
};

export default Profile;
